import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/portfolio.js";
import { graphql } from "gatsby";
export const query = graphql`
  query {
    finforce1: file(relativePath: { eq: "Financial_Force/ff-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    finforce2: file(relativePath: { eq: "Financial_Force/ff-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    finforce3: file(relativePath: { eq: "Financial_Force/ff-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridTwo = makeShortcode("GridTwo");
const ImageContainer = makeShortcode("ImageContainer");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <GridTwo mdxType="GridTwo">
      <ImageContainer classes="large" fluid={props.data.finforce1.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="large" fluid={props.data.finforce2.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.finforce3.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small right blog-text">Please contact me if you'd like to learn more about this project.</p>
    </GridTwo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      